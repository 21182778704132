.channel p {
    margin:0;
}

p {
    margin:0;
    overflow-wrap: break-word;
}

iframe {
    max-width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}